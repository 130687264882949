export function IsValidToken(token, expirationTime) {
  if (token === null) {
    return false;
  }

  const currentTime = new Date().getTime();
  const tokenExpirationTime = new Date(expirationTime).getTime();

  if (currentTime >= tokenExpirationTime) {
    return false;
  }

  return true;
}
