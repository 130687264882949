import React, { useRef, useState } from "react";

import Modal from "react-modal";

import { deletePostById, updatePostImage } from "../api/PostService";

import "../css/modal.css";
import "../css/components.css";

const PostComponent = ({ post }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const inputRef = useRef();

  const handleRespond = () => {
    if (localStorage.getItem("user") === null) {
      window.location.href = "/login";
    }

    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  let user = JSON.parse(localStorage.getItem("user"));

  const changeImage = (event) => {
    updatePostImage(
      localStorage.getItem("token"),
      post.id,
      event.target.files[0],
    ).then((imageUrl) => {
      window.location.reload();
    });
  };

  const deletePost = () => {
    if (
      window.confirm(
        "Ви впевнені, що хочете видалити цей пост? Ця дія незворотня.",
      )
    ) {
      deletePostById(localStorage.getItem("token"), post.id).then(() => {
        window.location.href = "/";
      });
    }
  };

  const selectImage = () => {
    inputRef.current.click();
  };

  return (
    <div className="post-page-body">
      <div className="post-page-image">
        <img src={post.imageUrl} alt={post.title} />
      </div>

      <div className="post-page-content">
        <h2>{post.title}</h2>
        <p>
          <span className="label">Ім'я:</span>{" "}
          <span className="value">{post.name}</span>
        </p>
        <p>
          <span className="label">Стать:</span>{" "}
          <span className="value">{post.gender}</span>
        </p>
        <p>
          <span className="label">Приблизний вік:</span>{" "}
          <span className="value">{post.age}</span>
        </p>
        <p>
          <span className="label">Адреса перебування:</span>{" "}
          <span className="value">{post.location}</span>
        </p>
        <p>
          <span className="label">Додаткова інформація:</span>{" "}
          <span className="value">{post.additionalInfo}</span>
        </p>
      </div>

      <div className="post-page-functions">
        {user !== null && user.id === post.creator?.id && (
          <button className="func-button" onClick={selectImage}>
            Змінити фото
          </button>
        )}
        {user !== null && user.id === post.creator?.id && (
          <button className="func-button" onClick={deletePost}>
            Видалити пост
          </button>
        )}

        <button className="func-button" onClick={handleRespond}>
          Відгукнутися
        </button>

        <input
          style={{ display: "none" }}
          type="file"
          name="file"
          accept="image/*"
          ref={inputRef}
          onChange={changeImage}
        />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Номер телефону"
        className="custom-modal"
      >
        <h2>Номер телефону:</h2>
        <p>
          <a href={"tel:+" + post.creator?.phoneNumber}>
            +{post.creator?.phoneNumber}
          </a>
        </p>
        <button className="modal-button" onClick={closeModal}>
          Закрити
        </button>
      </Modal>
    </div>
  );
};

export default PostComponent;
