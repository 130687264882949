import React, { useRef } from "react";

import { useSearchParams } from "react-router-dom";

import { updatePostImage } from "../api/PostService";
import { updateEventImage } from "../api/EventService";

import "../css/components.css";

const NewPhotoComponent = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const inputRef = useRef();

  if (localStorage.getItem("user") === null) {
    window.location.href = "/login";
  }

  const uploadImage = (event) => {
    if (type === "post") {
      updatePostImage(
        localStorage.getItem("token"),
        id,
        event.target.files[0],
      ).then((imageUrl) => {
        window.location.href = "/";
      });
      return;
    } else if (type === "event") {
      updateEventImage(
        localStorage.getItem("token"),
        id,
        event.target.files[0],
      ).then((imageUrl) => {
        window.location.href = "/";
      });
      return;
    }
  };

  const selectImage = () => {
    inputRef.current.click();
  };

  return (
    <div className="signUpContainer">
      <div className="loginRectangle">
        <h2 className="loginCaption">Нове фото</h2>

        <button className="loginButton" onClick={selectImage}>
          Завантажити фото
        </button>

        <input
          style={{ display: "none" }}
          type="file"
          name="file"
          accept="image/*"
          ref={inputRef}
          onChange={uploadImage}
        />
      </div>
    </div>
  );
};

export default NewPhotoComponent;
