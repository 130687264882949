import HeaderComponent from "../components/HeaderComponent";
import NewPostComponent from "../components/NewPostComponent";

export default function NewPost() {
  return (
    <div className="newpost">
      <HeaderComponent />
      <NewPostComponent />
    </div>
  );
}
