import HeaderComponent from "../components/HeaderComponent";
import AccountComponent from "../components/AccountComponent";

export default function Account() {
  if (localStorage.getItem("user") === null) {
    window.location.href = "/login";
  }

  return (
    <div className="useraccount">
      <HeaderComponent usedButton={"Аккаунт"} />
      <AccountComponent />
    </div>
  );
}
