import React, { useState } from "react";

import { createUser } from "../api/UserService";

const SignUp = () => {
  const [userType, setUserType] = useState("User");

  function signUp(formData) {
    formData.preventDefault();
    const name = formData.target[1].value;
    const email = formData.target[2].value;
    const phoneNumber = formData.target[3].value;
    const password = formData.target[4].value;
    const passwordConfirm = formData.target[5].value;
    const address = formData.target[8].value;

    if (password !== passwordConfirm) {
      alert("Passwords doesn't match.");
      return;
    }

    if (userType === "User") {
      let user = {
        userType: userType,
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        password: password,
      };

      createUser(user)
        .then((session) => {
          if (session !== undefined && session !== null && session.id) {
            localStorage.setItem("token", session.id);
            localStorage.setItem("expires", session.expiryDate);
            localStorage.setItem("user", JSON.stringify(session.user));
            window.location.href = "/";
          } else {
            alert("Failed to sign up.");
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            alert("User with this email already exists.");
          } else {
            alert("Failed to sign up.");
          }
        });
    } else if (userType === "Shelter") {
      let shelter = {
        userType: userType,
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        password: password,
        address: address,
      };

      createUser(shelter)
        .then((session) => {
          if (session !== undefined && session !== null && session.id) {
            localStorage.setItem("token", session.id);
            localStorage.setItem("expires", session.expiryDate);
            localStorage.setItem("user", JSON.stringify(session.user));
            window.location.href = "/";
          } else {
            alert("Failed to sign up.");
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            alert("User with this email already exists.");
          } else {
            alert("Failed to sign up.");
          }
        });
    }
  }

  return (
    <form className="form form-signup" onSubmit={signUp}>
      <fieldset>
        {userType === "User" ? (
          <div className="input-block">
            <label htmlFor="signup-user">Ім'я і прізвище</label>
            <input
              id="signup-user"
              type="text"
              placeholder="Введіть ваше ім'я"
              required
            />
          </div>
        ) : (
          <div className="input-block">
            <label htmlFor="signup-shelter">Назва притулку</label>
            <input
              id="signup-shelter"
              type="text"
              placeholder="Введіть назву притулку"
              required
            />
          </div>
        )}
        <div className="input-block">
          <label htmlFor="signup-email">Емейл</label>
          <input
            id="signup-email"
            type="email"
            placeholder="Введіть ваш email"
            pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
            required
          />
        </div>
        <div className="input-block">
          <label htmlFor="signup-email">Номер телефону</label>
          <input
            id="signup-phone-number"
            type="tel"
            placeholder="380680000000"
            pattern="[0-9]{3}[0-9]{2}[0-9]{7}"
            required
          />
        </div>
        <div className="input-block">
          <label htmlFor="signup-password">Пароль</label>
          <input id="signup-password" type="password" required />
        </div>
        <div className="input-block">
          <label htmlFor="signup-password-confirm">Підтвердіть пароль</label>
          <input id="signup-password-confirm" type="password" required />
        </div>
        <div className="input-block">
          <div className="radio-group">
            <label className="radio-label">
              <input
                type="radio"
                name="userType"
                value="user"
                checked={userType === "User"}
                onChange={() => setUserType("User")}
                className="radio-input"
              />
              Зареєструватись як користувач
            </label>
            <label className="radio-label">
              <input
                type="radio"
                name="userType"
                value="shelter"
                checked={userType === "Shelter"}
                onChange={() => setUserType("Shelter")}
                className="radio-input"
              />
              Зареєструватись як притулок
            </label>
          </div>
        </div>
        {userType === "Shelter" && (
          <div className="input-block">
            <label htmlFor="signup-address">Адреса</label>
            <input
              id="signup-address"
              type="text"
              placeholder="Введіть адресу притулку"
              required
            />
          </div>
        )}
      </fieldset>
      <button type="submit" className="btn-signup">
        Зареєструватись
      </button>
    </form>
  );
};

export default SignUp;
