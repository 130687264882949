import React, { useEffect, useState } from "react";

import { getPosts } from "../api/PostService";
import { getEvents } from "../api/EventService";
import { shuffleArray } from "../utils/arrayUtils";

import "../css/components.css";

const Gallery = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let posts = await getPosts();
      posts.map((post) => (post.type = "Post"));
      let events = await getEvents();
      events.map((event) => (event.type = "Event"));
      let postsList = posts.concat(events);
      setData(postsList);
    };
    fetchData();
  }, []);

  let posts = data;

  shuffleArray(posts);

  let photos = posts.map((post) => {
    return {
      id: post.id,
      type: post.type,
      src: post.imageUrl,
      alt: post.title,
    };
  });

  return (
    <div className="gallery-container">
      <div className="photo-grid">
        {photos.map((photo) => (
          <div
            key={photo.id}
            className="photo-item"
            id={
              (photo.type === "Post" ? "post" : "event") + "-photo_" + photo.id
            }
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.location =
                (photo.type === "Post" ? "/post/" : "/event/") + photo.id;
            }}
          >
            <img src={photo.src} alt={photo.alt} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
