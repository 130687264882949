import React from "react";

import Functions from "./Functions.js";
import UserDescription from "./UserDescription.js";
import ShelterDescription from "./ShelterDescription.js";

import "../css/components.css";

const AccountComponent = () => {
  if (localStorage.getItem("user") === null) {
    window.location.href = "/login";
  }

  let user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="acc-container">
      <Functions />
      {user.userType === "User" ? <UserDescription /> : <ShelterDescription />}
    </div>
  );
};

export default AccountComponent;
