import { createPost as newPost } from "../api/PostService";

import "../css/components.css";

const NewPostComponent = () => {
  if (localStorage.getItem("user") === null) {
    window.location.href = "/login";
  }

  function createPost(formData) {
    formData.preventDefault();
    const title = formData.target[0].value;
    const name = formData.target[1].value;
    const gender = formData.target[2].value;
    const age = formData.target[3].value;
    const location = formData.target[4].value;
    const additionalInfo = formData.target[5].value;

    let post = {
      title: title,
      name: name,
      gender: gender,
      age: age,
      location: location,
      additionalInfo: additionalInfo,
    };

    newPost(localStorage.getItem("token"), post).then((post) => {
      if (post !== undefined && post !== null && post.id) {
        window.location.href = "/newphoto?id=" + post.id + "&type=post";
      } else {
        alert("Failed to create post.");
      }
    });
  }

  return (
    <div className="signUpContainer">
      <div className="signUpRectangle">
        <h2 className="loginCaption">Новий пост</h2>

        <form className="signUpForm" onSubmit={createPost}>
          <input
            id="newpost-title"
            type="text"
            className="inputField"
            placeholder="Заголовок поста"
            required
          />
          <input
            id="newpost-name"
            type="text"
            className="inputField"
            placeholder="Імʼя тварини"
            required
          />
          <input
            id="newpost-gender"
            type="text"
            className="inputField"
            placeholder="Стать"
            required
          />
          <input
            id="newpost-age"
            type="text"
            className="inputField"
            placeholder="Вік(приблизно)"
            required
          />
          <input
            id="newpost-location"
            type="text"
            className="inputField"
            placeholder="Адреса перебування"
            required
          />
          <input
            id="newpost-additionalInfo"
            type="text"
            className="inputField"
            placeholder="Додаткова інформація (опишіть, будь ласка, які документи має тварина)"
            required
          />
          <button type="submit" className="loginButton">
            Створити пост
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewPostComponent;
