import React, { useState } from "react";

import Modal from "react-modal";

import "../css/modal.css";
import "../css/components.css";

const EventPost = ({
  id,
  title,
  date,
  location,
  contacts,
  imageUrl,
  creator,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleRespond = () => {
    if (localStorage.getItem("user") === null) {
      window.location.href = "/login";
    }

    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="post" id={"event_" + id}>
      <img
        src={imageUrl}
        alt="Event"
        className="post-image"
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.location = "/event/" + id;
        }}
      />
      <div className="post-content">
        <h2
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.location = "/event/" + id;
          }}
        >
          {title}
        </h2>
        <p>
          <span className="label">Дата:</span>{" "}
          <span className="value">{date}</span>
        </p>
        <p>
          <span className="label">Адреса:</span>{" "}
          <span className="value">{location}</span>
        </p>
        <p>
          <span className="label">Контакти організаторів:</span>{" "}
          <span className="value">{contacts}</span>
        </p>
        <button className="respond-button" onClick={handleRespond}>
          Відгукнутися
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Номер телефону"
        className="custom-modal"
      >
        <h2>Номер телефону:</h2>
        <p>
          <a href={"tel:+" + creator.phoneNumber}>+{creator.phoneNumber}</a>
        </p>
        <button className="modal-button" onClick={closeModal}>
          Закрити
        </button>
      </Modal>
    </div>
  );
};

export default EventPost;
