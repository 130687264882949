const API_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "";

const API_PATH = "/api/posts";

// Function to create a new post
export async function createPost(token, post) {
  return fetch(API_URL + API_PATH, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(post),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        let err = new Error("Unauthorized");
        err.response = response;
        throw err;
      } else {
        let err = new Error("Failed to create post");
        err.response = response;
        throw err;
      }
    })
    .then((post) => {
      // Handle successful post creation
      console.log("Post created:", post);

      return post;
    })
    .catch((error) => {
      // Handle error
      console.error("Error creating post:", error.message);
      throw error;
    });
}

// Function to get all posts
export async function getPosts() {
  return fetch(API_URL + API_PATH, { method: "GET" })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        let err = new Error("Failed to fetch posts");
        err.response = response;
        throw err;
      }
    })
    .then((posts) => {
      // Handle successful retrieval of posts
      console.log("Posts:", posts);

      return posts;
    })
    .catch((error) => {
      // Handle error
      console.error("Error fetching posts:", error.message);
      throw error;
    });
}

// Function to get the current user's posts based on the authorization token
export async function getMyPosts(token) {
  return fetch(API_URL + API_PATH + "/@me", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        let err = new Error("Unauthorized");
        err.response = response;
        throw err;
      } else {
        let err = new Error("Failed to fetch user posts");
        err.response = response;
        throw err;
      }
    })
    .then((posts) => {
      // Handle successful retrieval of user posts
      console.log("User posts:", posts);

      return posts;
    })
    .catch((error) => {
      // Handle error
      console.error("Error fetching user posts:", error.message);
      throw error;
    });
}

// Function to get a post by ID
export async function getPostById(id) {
  return fetch(API_URL + API_PATH + "/" + id, { method: "GET" })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 404) {
        let err = new Error("Post not found");
        err.response = response;
        throw err;
      } else {
        let err = new Error("Failed to fetch post");
        err.response = response;
        throw err;
      }
    })
    .then((post) => {
      // Handle successful retrieval of post
      console.log("Post:", post);

      return post;
    })
    .catch((error) => {
      // Handle error
      console.error("Error fetching post:", error.message);
      throw error;
    });
}

// Function to delete a post by ID
export async function deletePostById(token, id) {
  return fetch(API_URL + API_PATH + "/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.status;
      } else if (response.status === 401) {
        let err = new Error("Unauthorized");
        err.response = response;
        throw err;
      } else if (response.status === 403) {
        let err = new Error("Forbidden");
        err.response = response;
        throw err;
      } else if (response.status === 404) {
        let err = new Error("Post not found");
        err.response = response;
        throw err;
      } else {
        let err = new Error("Failed to delete post");
        err.response = response;
        throw err;
      }
    })
    .then((message) => {
      // Handle successful post deletion
      console.log("Post deleted:", message);

      return message;
    })
    .catch((error) => {
      // Handle error
      console.error("Error deleting post:", error.message);
      throw error;
    });
}

// Function to update a post's image
export async function updatePostImage(token, id, image) {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("file", image, image.name);

  return fetch(API_URL + API_PATH + "/image", {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
    },
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else if (response.status === 401) {
        let err = new Error("Unauthorized");
        err.response = response;
        throw err;
      } else if (response.status === 403) {
        let err = new Error("Forbidden");
        err.response = response;
        throw err;
      } else if (response.status === 404) {
        let err = new Error("Post not found");
        err.response = response;
        throw err;
      } else if (response.status === 400) {
        let err = new Error("Bad request");
        err.response = response;
        throw err;
      } else {
        let err = new Error("Failed to update post image");
        err.response = response;
        throw err;
      }
    })
    .then((imageUrl) => {
      // Handle successful post image update
      console.log("Post image updated:", imageUrl);

      return imageUrl;
    })
    .catch((error) => {
      // Handle error
      console.error("Error updating post image:", error.message);
      throw error;
    });
}

// Function to get a post's image
export async function getPostImage(filename) {
  return fetch(API_URL + API_PATH + "/image/" + filename, { method: "GET" })
    .then((response) => {
      if (response.ok) {
        return response.blob();
      } else {
        let err = new Error("Failed to fetch post image");
        err.response = response;
        throw err;
      }
    })
    .then((blob) => {
      // Handle successful retrieval of post image
      const url = URL.createObjectURL(blob);
      console.log("Post image URL:", url);

      return url;
    })
    .catch((error) => {
      // Handle error
      console.error("Error fetching post image:", error.message);
      throw error;
    });
}
