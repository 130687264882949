import HeaderComponent from "../components/HeaderComponent";

export default function About() {
  return (
    <div className="about">
      <HeaderComponent usedButton={"Про нас"} />
      <h1>Інформація про наш сайт</h1>
      <p>
        Наш сайт створений для допомоги бездомним тваринам та притулкам. Він
        полегшить комунікацію між притулками та волонтерами, а також дозволить
        користувачам розміщувати оголошення про тварин, що шукають домівку.
        Оскільки платформа буде вузько направмлена, то це дозволить зібрати коло
        однодумців. Також можливість фільтрації дозволить знайти потрібний вид
        оголошення та тварин за різними критеріями.
      </p>
    </div>
  );
}
