import { createEvent as newEvent } from "../api/EventService";

import "../css/components.css";

const NewEventComponent = () => {
  if (localStorage.getItem("user") === null) {
    window.location.href = "/login";
  }

  if (JSON.parse(localStorage.getItem("user")).userType !== "Shelter") {
    window.location.href = "/";
  }

  function createEvent(formData) {
    formData.preventDefault();
    const title = formData.target[0].value;
    const date = formData.target[1].value;
    const location = formData.target[2].value;
    const contacts = formData.target[3].value;

    let event = {
      title: title,
      date: date,
      location: location,
      contacts: contacts,
    };

    newEvent(localStorage.getItem("token"), event).then((event) => {
      if (event !== undefined && event !== null && event.id) {
        window.location.href = "/newphoto?id=" + event.id + "&type=event";
      } else {
        alert("Failed to create event.");
      }
    });
  }

  return (
    <div className="signUpContainer">
      <div className="newEventRectangle">
        <h2 className="loginCaption">Нова подія</h2>

        <form className="signUpForm" onSubmit={createEvent}>
          <input
            id="newevent-title"
            type="text"
            className="inputField"
            placeholder="Заголовок події"
            required
          />
          <input
            id="newevent-date"
            type="text"
            className="inputField"
            placeholder="Дата"
            required
          />
          <input
            id="newevent-location"
            type="text"
            className="inputField"
            placeholder="Адреса"
            required
          />
          <input
            id="newevent-contacts"
            type="text"
            className="inputField"
            placeholder="Контакти організаторів"
            required
          />
          <button type="submit" className="loginButton">
            Cтворити подію
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewEventComponent;
