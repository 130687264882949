import React, { useRef } from "react";

import { updateAvatar } from "../api/UserService";

const Functions = () => {
  const inputRef = useRef();

  if (localStorage.getItem("user") === null) {
    window.location.href = "/login";
  }

  let user = JSON.parse(localStorage.getItem("user"));

  const changeAvatar = (event) => {
    updateAvatar(localStorage.getItem("token"), event.target.files[0]).then(
      (avatarUrl) => {
        if (avatarUrl !== null) {
          user.avatarUrl = avatarUrl;
          localStorage.setItem("user", JSON.stringify(user));
          window.location.reload();
        }
      },
    );
  };

  const selectImage = () => {
    inputRef.current.click();
  };

  function logOut() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("expires");
    window.location.href = "/login";
  }

  return (
    <div className="func">
      <img
        src={user.avatarUrl !== undefined ? user.avatarUrl : "/images/user.png"}
        alt="user"
        className="acc-photo"
      />

      <button className="func-button" onClick={selectImage}>
        Змінити аватар
      </button>
      <button className="func-button" onClick={logOut}>
        Вийти
      </button>

      <input
        style={{ display: "none" }}
        type="file"
        name="file"
        accept="image/*"
        ref={inputRef}
        onChange={changeAvatar}
      />
    </div>
  );
};

export default Functions;
