import HeaderComponent from "../components/HeaderComponent";
import NewPhotoComponent from "../components/NewPhotoComponent";

export default function NewPhoto() {
  return (
    <div className="newevent">
      <HeaderComponent />
      <NewPhotoComponent />
    </div>
  );
}
