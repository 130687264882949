const API_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "";

const API_PATH = "/api/users";

export async function createUser(user) {
  // Create a new user
  return fetch(API_URL + API_PATH, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 409) {
        let err = new Error("User already exists");
        err.response = response;
        throw err;
      } else {
        let err = new Error("Failed to create user");
        err.response = response;
        throw err;
      }
    })
    .then((session) => {
      // Handle successful user creation and session creation
      console.log("User created:", session.user);
      console.log("Session created:", session);

      return session;
    })
    .catch((error) => {
      // Handle error
      console.error("Error creating user:", error.message);
      throw error;
    });
}

export async function getUsers() {
  // Get all users
  return fetch(API_URL + API_PATH, { method: "GET" })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        let err = new Error("Failed to get users");
        err.response = response;
        throw err;
      }
    })
    .then((users) => {
      // Handle successful retrieval of users
      console.log("Users:", users);

      return users;
    })
    .catch((error) => {
      // Handle error
      console.error("Error getting users:", error.message);
      throw error;
    });
}

export async function getCurrentUser(token) {
  // Get current user
  return fetch(API_URL + API_PATH + "/@me", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        let err = new Error("Unauthorized");
        err.response = response;
        throw err;
      } else {
        let err = new Error("Failed to get current user");
        err.response = response;
        throw err;
      }
    })
    .then((user) => {
      // Handle successful retrieval of current user
      console.log("Current user:", user);

      return user;
    })
    .catch((error) => {
      // Handle error
      console.error("Error getting current user:", error.message);
      throw error;
    });
}

export async function getUserById(id) {
  // Get user by ID
  return fetch(API_URL + API_PATH + "/" + id, { method: "GET" })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        let err = new Error("Failed to get user");
        err.response = response;
        throw err;
      }
    })
    .then((user) => {
      // Handle successful retrieval of user
      console.log("User:", user);

      return user;
    })
    .catch((error) => {
      // Handle error
      console.error("Error getting user:", error.message);
      throw error;
    });
}

export async function updateAvatar(token, avatar) {
  // Update user avatar
  const formData = new FormData();
  formData.append("file", avatar, avatar.name);

  return fetch(API_URL + API_PATH + "/avatar", {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
    },
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        let err = new Error("Failed to update user avatar");
        err.response = response;
        throw err;
      }
    })
    .then((avatarUrl) => {
      // Handle successful update of user avatar
      console.log("User avatar updated:", avatarUrl);

      return avatarUrl;
    })
    .catch((error) => {
      // Handle error
      console.error("Error updating user avatar:", error.message);
      throw error;
    });
}

export async function getAvatar(filename) {
  // Get user avatar
  return fetch(API_URL + API_PATH + "/avatar/" + filename, { method: "GET" })
    .then((response) => {
      if (response.ok) {
        return response.blob();
      } else {
        let err = new Error("Failed to get user avatar");
        err.response = response;
        throw err;
      }
    })
    .then((blob) => {
      // Handle successful retrieval of user avatar
      const url = URL.createObjectURL(blob);
      console.log("User avatar URL:", url);

      return url;
    })
    .catch((error) => {
      // Handle error
      console.error("Error getting user avatar:", error.message);
      throw error;
    });
}
