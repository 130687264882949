const API_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "";

const API_PATH = "/api/events";

// Function to create a new event
export async function createEvent(token, event) {
  return fetch(API_URL + API_PATH, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(event),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        let err = new Error("Unauthorized");
        err.response = response;
        throw err;
      } else if (response.status === 403) {
        let err = new Error("Forbidden");
        err.response = response;
        throw err;
      } else {
        let err = new Error("Failed to create event");
        err.response = response;
        throw err;
      }
    })
    .then((event) => {
      // Handle successful event creation
      console.log("Event created:", event);

      return event;
    })
    .catch((error) => {
      // Handle error
      console.error("Error creating event:", error.message);
      throw error;
    });
}

// Function to get all events
export async function getEvents() {
  return fetch(API_URL + API_PATH, { method: "GET" })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        let err = new Error("Failed to fetch events");
        err.response = response;
        throw err;
      }
    })
    .then((events) => {
      // Handle successful retrieval of events
      console.log("Events:", events);

      return events;
    })
    .catch((error) => {
      // Handle error
      console.error("Error fetching events:", error.message);
      throw error;
    });
}

// Function to get the current user's events based on the authorization token
export async function getMyEvents(token) {
  return fetch(API_URL + API_PATH + "/@me", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        let err = new Error("Unauthorized");
        err.response = response;
        throw err;
      } else {
        let err = new Error("Failed to fetch events");
        err.response = response;
        throw err;
      }
    })
    .then((events) => {
      // Handle successful retrieval of events
      console.log("User events:", events);

      return events;
    })
    .catch((error) => {
      // Handle error
      console.error("Error fetching user events:", error.message);
      throw error;
    });
}

// Function to get an event by ID
export async function getEventById(id) {
  return fetch(API_URL + API_PATH + "/" + id, { method: "GET" })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 404) {
        let err = new Error("Event not found");
        err.response = response;
        throw err;
      } else {
        let err = new Error("Failed to fetch event");
        err.response = response;
        throw err;
      }
    })
    .then((event) => {
      // Handle successful retrieval of event
      console.log("Event:", event);

      return event;
    })
    .catch((error) => {
      // Handle error
      console.error("Error fetching event:", error.message);
      throw error;
    });
}

// Function to delete a event by ID
export async function deleteEventById(token, id) {
  return fetch(API_URL + API_PATH + "/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.status;
      } else if (response.status === 401) {
        let err = new Error("Unauthorized");
        err.response = response;
        throw err;
      } else if (response.status === 403) {
        let err = new Error("Forbidden");
        err.response = response;
        throw err;
      } else if (response.status === 404) {
        let err = new Error("Event not found");
        err.response = response;
        throw err;
      } else {
        let err = new Error("Failed to delete event");
        err.response = response;
        throw err;
      }
    })
    .then((message) => {
      // Handle successful event deletion
      console.log("Event deleted:", message);

      return message;
    })
    .catch((error) => {
      // Handle error
      console.error("Error deleting event:", error.message);
      throw error;
    });
}

// Function to update a event's image
export async function updateEventImage(token, id, image) {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("file", image, image.name);

  return fetch(API_URL + API_PATH + "/image", {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
    },
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else if (response.status === 401) {
        let err = new Error("Unauthorized");
        err.response = response;
        throw err;
      } else if (response.status === 403) {
        let err = new Error("Forbidden");
        err.response = response;
        throw err;
      } else if (response.status === 404) {
        let err = new Error("Event not found");
        err.response = response;
        throw err;
      } else if (response.status === 400) {
        let err = new Error("Bad request");
        err.response = response;
        throw err;
      } else {
        let err = new Error("Failed to update event image");
        err.response = response;
        throw err;
      }
    })
    .then((imageUrl) => {
      // Handle successful update of event image
      console.log("Event image updated:", imageUrl);

      return imageUrl;
    })
    .catch((error) => {
      // Handle error
      console.error("Error updating event image:", error.message);
      throw error;
    });
}

// Function to get a event's image
export async function getEventImage(filename) {
  return fetch(API_URL + API_PATH + "/image/" + filename, { method: "GET" })
    .then((response) => {
      if (response.ok) {
        return response.blob();
      } else {
        let err = new Error("Failed to fetch event image");
        err.response = response;
        throw err;
      }
    })
    .then((blob) => {
      // Handle successful retrieval of event image
      const url = URL.createObjectURL(blob);
      console.log("Event image URL:", url);

      return url;
    })
    .catch((error) => {
      // Handle error
      console.error("Error fetching event image:", error.message);
      throw error;
    });
}
