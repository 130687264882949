import React from "react";

import "../css/modal.css";
import "../css/components.css";

const PostTypeModal = ({ active, closeModal, openPage }) => {
  if (!active) return null; // Якщо modalActive false, не відображати модальне вікно

  if (localStorage.getItem("user") === null) {
    window.location.href = "/login";
  }

  const handleButtonClick = (page) => {
    openPage(page);
    closeModal();
  };

  let user = JSON.parse(localStorage.getItem("user"));

  return (
    <div
      className="modal-backdrop"
      onClick={() => {
        closeModal();
      }}
    >
      <div className="modal">
        <div className="modal-content">
          <p>Який пост ви хочете додати?</p>
          <button
            className="modal-button"
            onClick={() => handleButtonClick("/newpost")}
          >
            Пост з тваринкою
          </button>
          {user.userType === "Shelter" && (
            <button
              className="modal-button"
              onClick={() => handleButtonClick("/newevent")}
            >
              Подія
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostTypeModal;
