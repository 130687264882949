import React, { useState } from "react";

import Login from "../components/Login";
import SignUp from "../components/SignUp";
import { IsValidToken } from "../utils/tokenUtils";
import { getCurrentUser } from "../api/UserService";
import HeaderComponent from "../components/HeaderComponent";

import "../css/AuthPage.css";

const AuthPage = () => {
  const [isActive, setIsActive] = useState("login");

  let token = localStorage.getItem("token");
  if (IsValidToken(token, localStorage.getItem("expires"))) {
    getCurrentUser(token)
      .then((user) => {
        if (
          user !== undefined &&
          user !== null &&
          user.id !== undefined &&
          user.id !== null
        ) {
          localStorage.setItem("user", JSON.stringify(user));
          window.location.href = "/";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <HeaderComponent usedButton={"Реєстрація"} />
      <section className="forms-section">
        <div className="forms">
          <div
            className={`form-wrapper ${isActive === "login" ? "is-active" : ""}`}
          >
            <button
              type="button"
              className="switcher switcher-login"
              onClick={() => setIsActive("login")}
            >
              Login
              <span className="underline"></span>
            </button>
            <Login />
          </div>
          <div
            className={`form-wrapper ${isActive === "signup" ? "is-active" : ""}`}
          >
            <button
              type="button"
              className="switcher switcher-signup"
              onClick={() => setIsActive("signup")}
            >
              Sign Up
              <span className="underline"></span>
            </button>
            <SignUp />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AuthPage;
