import React, { useState } from "react";

import PostTypeModal from "./PostTypeModal";

const AddPostButton = () => {
  const [modalActive, setModalActive] = useState(false); // Початково модальне вікно неактивне

  const openModal = () => {
    setModalActive(true);
  };

  const closeModal = () => {
    setModalActive(false);
  };

  const openPage = (page) => {
    window.location.href = page;
  };
  return (
    <div className="upload-photo-container">
      <button className="func-button" onClick={openModal}>
        Додати пост
      </button>
      <PostTypeModal
        active={modalActive}
        closeModal={closeModal}
        openPage={openPage}
      ></PostTypeModal>
    </div>
  );
};

export default AddPostButton;
