import Gallery from "../components/Gallery";
import HeaderComponent from "../components/HeaderComponent.js";

export default function PhotoGallery() {
  return (
    <div className="shelterfeed">
      <HeaderComponent usedButton={"Фотогалерея"} />
      <Gallery />
    </div>
  );
}
