import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { getPostById } from "../api/PostService";
import PostComponent from "../components/PostComponent";
import HeaderComponent from "../components/HeaderComponent";

import "../css/pages.css";

export default function Post() {
  const postId = useParams().postId;

  const [post, setPost] = useState({});

  useEffect(() => {
    const fetchPost = async () => {
      let post = await getPostById(postId).catch((error) => {
        if (error.response.status === 404) {
          console.error("Post not found");
          window.location.href = "/";
        } else {
          console.error("Error fetching post:", error.message);
          throw error;
        }
      });
      setPost(post);
    };
    fetchPost();
  }, [postId]);

  return (
    <div className="post-page">
      <HeaderComponent />
      <PostComponent key={post.id} post={post} />
    </div>
  );
}
