import PostList from "../components/PostList";
import AddPostButton from "../components/AddPostButton";
import HeaderComponent from "../components/HeaderComponent";

import "../css/pages.css";

export default function Home() {
  return (
    <div className="home">
      <HeaderComponent usedButton={"Всі пости"} />
      <AddPostButton />
      <PostList />
    </div>
  );
}
