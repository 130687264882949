import React from "react";

import { login as loginWithCredentials } from "../api/LoginService";

const Login = () => {
  function login(formData) {
    formData.preventDefault();
    const email = formData.target[1].value;
    const password = formData.target[2].value;

    loginWithCredentials(email, password).then((session) => {
      if (session !== undefined && session !== null && session.id) {
        localStorage.setItem("token", session.id);
        localStorage.setItem("expires", session.expiryDate);
        localStorage.setItem("user", JSON.stringify(session.user));
        window.location.href = "/";
      } else {
        alert("Wrong email or password.");
      }
    });
  }

  return (
    <form className="form form-login" onSubmit={login}>
      <fieldset>
        <legend>Please, enter your email and password for login.</legend>
        <div className="input-block">
          <label htmlFor="login-email">Емейл</label>
          <input id="login-email" type="email" required />
        </div>
        <div className="input-block">
          <label htmlFor="login-password">Пароль</label>
          <input id="login-password" type="password" required />
        </div>
      </fieldset>
      <button type="submit" className="btn-login">
        Увійти
      </button>
    </form>
  );
};

export default Login;
