import React from "react";

const ShelterDescriptionn = () => {
  if (localStorage.getItem("user") === null) {
    window.location.href = "/login";
  }

  let user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="description">
      <p>Назва притулку: {user.name}</p>
      <p>Адреса: {user.address}</p>
      <p>Електронна пошта: {user.email}</p>
      <p>Номер телефону: +{user.phoneNumber}</p>
    </div>
  );
};

export default ShelterDescriptionn;
