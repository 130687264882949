import React from "react";

const UserDescription = () => {
  if (localStorage.getItem("user") === null) {
    window.location.href = "/login";
  }

  let user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="description">
      <p>Ім'я та прізвище: {user.name}</p>
      <p>Електронна пошта: {user.email}</p>
      <p>Номер телефону: +{user.phoneNumber}</p>
    </div>
  );
};

export default UserDescription;
