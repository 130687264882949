import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { getEventById } from "../api/EventService";
import EventComponent from "../components/EventComponent";
import HeaderComponent from "../components/HeaderComponent";

import "../css/pages.css";

export default function Event() {
  const eventId = useParams().eventId;

  const [event, setEvent] = useState([]);

  useEffect(() => {
    const fetchEvent = async () => {
      let event = await getEventById(eventId).catch((error) => {
        if (error.response.status === 404) {
          console.error("Event not found");
          window.location.href = "/";
        } else {
          console.error("Error fetching event:", error.message);
          throw error;
        }
      });
      setEvent(event);
    };
    fetchEvent();
  }, [eventId]);

  return (
    <div className="event-page">
      <HeaderComponent />
      <EventComponent key={event.id} eventData={event} />
    </div>
  );
}
