import React from "react";

import { Link } from "react-router-dom";

import "../css/components.css";

const HeaderComponent = ({ usedButton }) => {
  if (localStorage.getItem("user") !== null) {
    var user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = null;
  }

  let buttons = [
    { label: "Всі пости", used: false, path: "/" },
    { label: "Фотогалерея", used: false, path: "/photogallery" },
    { label: "Про нас", used: false, path: "/about" },
    user === null
      ? { label: "Реєстрація", used: false, path: "/login" }
      : { label: "Аккаунт", used: false, path: "/account" },
  ];

  if (usedButton !== undefined && usedButton !== null && usedButton !== "") {
    buttons
      .filter((button) => button.label === usedButton)
      .map((button) => (button.used = true));
  }

  return (
    <div className="container">
      <div className={`flex-rectangle`}>
        <img src="/logo.png" alt="Лапка" className="icon" />
        {buttons.length > 0 &&
          buttons.map((button, index) => (
            <Link
              key={index}
              to={button.path}
              className={button.used ? "used-header-button" : "header-button"}
            >
              {button.label}
            </Link>
          ))}
      </div>
    </div>
  );
};

export default HeaderComponent;
