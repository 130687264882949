import HeaderComponent from "../components/HeaderComponent";
import NewEventComponent from "../components/NewEventComponent";

export default function NewEvent() {
  return (
    <div className="newevent">
      <HeaderComponent />
      <NewEventComponent />
    </div>
  );
}
