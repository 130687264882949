import React, { useEffect, useState } from "react";

import EventPost from "./EventPost";
import FilterBox from "./FilterBox";
import AnimalPost from "./AnimalPost";
import { getPosts } from "../api/PostService";
import { getEvents } from "../api/EventService";

const PostList = () => {
  const [filter, setFilter] = useState(null);
  const [data, setData] = useState([]);

  const handleFilterChange = (type) => {
    setFilter(type);
  };

  useEffect(() => {
    const fetchData = async () => {
      let posts = await getPosts();
      posts.map((post) => (post.type = "Post"));
      let events = await getEvents();
      events.map((event) => (event.type = "Event"));
      let postsList = posts.concat(events);
      setData(postsList);
    };
    fetchData();
  }, []);

  let posts = data;

  const filteredPosts =
    filter !== null
      ? filter === "@me" && localStorage.getItem("user") !== null
        ? posts.filter(
            (post) =>
              post.creator.id === JSON.parse(localStorage.getItem("user")).id,
          )
        : posts.filter((post) => post.type === filter)
      : posts;

  return (
    <div className="posts-list-container">
      <FilterBox onFilterChange={handleFilterChange} />{" "}
      {/* Фільтер бокс поза стрічкою постів */}
      <div className="posts-list">
        {filteredPosts.map((post) =>
          post.type === "Post" ? (
            <AnimalPost
              key={post.id}
              id={post.id}
              title={post.title}
              name={post.name}
              gender={post.gender}
              age={post.age}
              location={post.location}
              additionalInfo={post.additionalInfo}
              imageUrl={post.imageUrl}
              creator={post.creator}
            />
          ) : (
            <EventPost
              key={post.id}
              id={post.id}
              title={post.title}
              date={post.date}
              location={post.location}
              contacts={post.contacts}
              imageUrl={post.imageUrl}
              creator={post.creator}
            />
          ),
        )}
      </div>
    </div>
  );
};

export default PostList;
