import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Post from "./pages/Post";
import About from "./pages/About";
import Event from "./pages/Event";
import NewPost from "./pages/NewPost";
import Account from "./pages/Account";
import NewPhoto from "./pages/NewPhoto";
import NewEvent from "./pages/NewEvent";
import AuthPage from "./pages/AuthPage";
import PhotoGallery from "./pages/PhotoGallery";

import "./css/App.css";

function App() {
  return (
    <BrowserRouter>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="login" element={<AuthPage />} />
          <Route path="signup" element={<AuthPage />} />
          <Route path="newpost" element={<NewPost />} />
          <Route path="newphoto" element={<NewPhoto />} />
          <Route path="newevent" element={<NewEvent />} />
          <Route path="photogallery" element={<PhotoGallery />} />
          <Route path="account" element={<Account />} />
          <Route path="post/:postId" element={<Post />} />
          <Route path="event/:eventId" element={<Event />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;
