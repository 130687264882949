import React, { useRef, useState } from "react";

import Modal from "react-modal";

import { deleteEventById, updateEventImage } from "../api/EventService";

import "../css/modal.css";
import "../css/components.css";

const EventComponent = ({ eventData }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const inputRef = useRef();

  const handleRespond = () => {
    if (localStorage.getItem("user") === null) {
      window.location.href = "/login";
    }

    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  let user = JSON.parse(localStorage.getItem("user"));

  const changeImage = (event) => {
    updateEventImage(
      localStorage.getItem("token"),
      eventData.id,
      event.target.files[0],
    ).then((imageUrl) => {
      window.location.reload();
    });
  };

  const deleteEvent = () => {
    if (
      window.confirm(
        "Ви впевнені, що хочете видалити цю подію? Ця дія незворотня.",
      )
    ) {
      deleteEventById(localStorage.getItem("token"), eventData.id).then(() => {
        window.location.href = "/";
      });
    }
  };

  const selectImage = () => {
    inputRef.current.click();
  };

  return (
    <div className="post-page-body">
      <div className="post-page-image">
        <img src={eventData.imageUrl} alt={eventData.title} />
      </div>

      <div className="post-page-content">
        <h2>{eventData.title}</h2>
        <p>
          <span className="label">Дата:</span>{" "}
          <span className="value">{eventData.date}</span>
        </p>
        <p>
          <span className="label">Адреса:</span>{" "}
          <span className="value">{eventData.location}</span>
        </p>
        <p>
          <span className="label">Контакти організаторів:</span>{" "}
          <span className="value">{eventData.contacts}</span>
        </p>
      </div>

      <div className="post-page-functions">
        {user !== null && user.id === eventData.creator?.id && (
          <button className="func-button" onClick={selectImage}>
            Змінити фото
          </button>
        )}
        {user !== null && user.id === eventData.creator?.id && (
          <button className="func-button" onClick={deleteEvent}>
            Видалити подію
          </button>
        )}

        <button className="func-button" onClick={handleRespond}>
          Відгукнутися
        </button>

        <input
          style={{ display: "none" }}
          type="file"
          name="file"
          accept="image/*"
          ref={inputRef}
          onChange={changeImage}
        />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Номер телефону"
        className="custom-modal"
      >
        <h2>Номер телефону:</h2>
        <p>
          <a href={"tel:+" + eventData.creator?.phoneNumber}>
            +{eventData.creator?.phoneNumber}
          </a>
        </p>
        <button className="modal-button" onClick={closeModal}>
          Закрити
        </button>
      </Modal>
    </div>
  );
};

export default EventComponent;
