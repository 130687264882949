const API_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "";

export async function login(email, password) {
  // Login
  return fetch(API_URL + "/api/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        let err = new Error("Failed to login");
        err.response = response;
        throw err;
      }
    })
    .then((session) => {
      // Handle successful login
      console.log("Login successful");
      console.log("Session:", session);

      return session;
    })
    .catch((error) => {
      // Handle error
      console.error("Error logging in:", error.message);
    });
}
