import React from "react";

import "../css/components.css";

const FilterBox = ({ onFilterChange }) => {
  return (
    <div className="filter-box">
      <button className="filter-button" onClick={() => onFilterChange("Post")}>
        Пости з тваринами
      </button>
      <button className="filter-button" onClick={() => onFilterChange("Event")}>
        Пости з подіями
      </button>
      {localStorage.getItem("user") !== null && (
        <button className="filter-button" onClick={() => onFilterChange("@me")}>
          Мої пости
        </button>
      )}
    </div>
  );
};

export default FilterBox;
